<template>
  <div class="rounded-lg bg-white px-5 py-2 drop-shadow-md">
    <div class="flex justify-between">
      <slot name="table-header"></slot>

      <div
        v-if="store.searchResults && store.searchResults.candidateData.length > 0"
        class="flex"
        :class="{
          'flex-row gap-4': store.searchResults.searchType !== 'lookalike',
          'flex-col': store.searchResults.searchType === 'lookalike'
        }"
      >
        <slot name="table-header-buttons"></slot>
        <button
          :class="{
            'bg-gray-200': riskScoreColumn.getSortIndex() !== 0,
            ring: riskScoreColumn.getSortIndex() === 0,
            'bg-white': riskScoreColumn.getSortIndex() === 0
          }"
          class="mb-4 flex h-9 w-[185px] items-center justify-around rounded"
          @click="sortByRiskScore"
        >
          <Loader v-if="riskScoreLoading" class="w-10" />
          <ToolTip
            v-else
            text="Our AI will sort the candidates by which are more open to being approached."
            css-classes="w-[180px] z-[11]"
          >
            <template #default>
              <div class="flex">
                <AiIcon class="ml-[-16px]" />
                <span class="mr-1 ml-1 text-sm inter-medium text-gray-600">Predicted Interest</span>
              </div>
            </template>
          </ToolTip>
        </button>

        <AllLookalikesTableFilter :table="table" @filter-table="table.setPageIndex(0)" />
      </div>
    </div>

    <div
      :class="`${tableHeight ?? 'max-h-[70]'} overflow-scroll overflow-x-hidden mt-4 `"
      ref="tableContainer"
    >
      <BaseTableClientSide
        v-if="candidateData && candidateData.length > 0 && !isLoading"
        :table="table"
        :key="tableKey"
        header-cell-padding-class="px-4 py-2"
        @row-clicked="viewCandidate"
      />
      <div v-if="isLoading" class="flex flex-col items-center justify-center py-8 px-6">
        <NovaTableIcon />
        <Loader v-if="isLoading" class="m-auto mt-5 w-14" />

        <p
          v-if="candidateData && candidateData.length === 0"
          class="mt-3 mb-3 max-w-[50%] text-base inter-medium text-gray-500"
        >
          No Lookalikes for this Archetype yet.
        </p>
      </div>
    </div>
    <Pagination
      v-if="props.candidateData && props.candidateData.length > 0"
      :table="table"
      @pageChange="scrollToTop"
    />
  </div>
</template>

<script lang="tsx" setup>
import { computed, defineEmits, defineProps, ref, watch, withModifiers } from 'vue'
import { useRouter } from 'vue-router'
import {
  createColumnHelper,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useVueTable
} from '@tanstack/vue-table'
import { mkConfig, generateCsv, download } from 'export-to-csv'

import { convertMonthsToYearsMonths } from '@/utils/date'
import { useLookalikesStore } from '@/stores/lookalikes'
import { locationShortenFormat } from '@/utils/location-formatter'

import BaseTableClientSide from '@/components/table/BaseTableClientSide.vue'
import ColumnSort from '@/components/table/ColumnSort.vue'
import CandidateShortlistButton from '@/components/CandidateShortlistButton.vue'
import AllLookalikesTableFilter from './AllLookalikesTableFilter.vue'
import ToolTip from '@/components/ToolTip.vue'
import BaseButton from '@/components/BaseButton.vue'
import FileDownloadIcon from '@/components/icons/FileDownloadIcon.vue'
import NovaTableIcon from '@/components/icons/NovaTableIcon.vue'
import Loader from '@/components/Loader.vue'
import AiIcon from '@/components/icons/AiIcon.vue'
import CompanyLogo from '@/components/CompanyLogo.vue'
import Pagination from '@/components/table/Pagination.vue'
import LinkedinIcon from '@/components/icons/LinkedinIcon.vue'
import RecentlyViewedCandidate from '@/components/RecentlyViewedCandidate.vue'
import { setRecentViewed } from '@/utils/recent-viewed-candidates'

interface IAllLookalikesData {
  archetypeProfileId: string
  candidateProfileId: string
  candidateId: number
  rank: number
  name: string
  position: string
  education: string
  location: string
  roleExp: number
  careerExp: number
  shortlisted: boolean
  industry: string
  riskScore: number
  country: string
  state: string
  city: string
  companyLiUrl: string
  companyLogo: string
  companyName: string
}

interface IAllLookalikeTable {
  candidateData: Record<string, any>[]
  archetypeData: Record<string, any>[]
  searchedJobTitle: string
  isLoading?: boolean
  tableHeight?: string
}

const emit = defineEmits(['shortlistSucceed', 'shortlistFailed'])

const store = useLookalikesStore()
const props = defineProps<IAllLookalikeTable>()
const router = useRouter()
const riskScoreLoading = ref(false)
const tableContainer = ref(null)
const tableKey = ref(0)

const columnHelper = createColumnHelper<IAllLookalikesData>()

const columns = [
  columnHelper.accessor('rank', {
    id: 'rank',
    header: () => <ColumnSort title={'Rank'} />,
    size: 60,
    enableSorting: true,
    cell: (prop) => <span class={'block py-4 pr-2 pl-4'}>{prop.getValue()}</span>
  }),
  columnHelper.accessor('name', {
    header: 'name',
    size: 115,
    cell: (prop) => (
      <a
        class={'flex'}
        href={`https://www.linkedin.com/in/${prop.row.original.candidateProfileId}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={withModifiers(() => {}, ['stop'])}
      >
        <LinkedinIcon class={'h-4 w-4 self-center'} />
        <span class={'block py-4 pl-2 pr-4'}>{prop.getValue()}</span>
        <RecentlyViewedCandidate
          class={'self-center text-gray-500 cursor-default'}
          candidateProfileId={prop.row.original.candidateProfileId}
          displayAtFirstTime="true"
        ></RecentlyViewedCandidate>
      </a>
    )
  }),
  columnHelper.accessor('position', {
    header: 'position',
    size: 180,
    cell: (prop) => <span class={'block p-4'}>{prop.getValue()}</span>
  }),
  columnHelper.accessor('employer', {
    header: () => <ColumnSort title={'Employer'} />,
    size: 100,
    cell: (prop) => (
      <div class="w-24 px-4 justify-center items-center flex py-3">
        <CompanyLogo
          src={prop.row.original.companyLogo}
          company-name={prop.row.original.companyName}
          link={prop.row.original.companyLiUrl}
        />
      </div>
    ),
    enableSorting: true
  }),
  columnHelper.accessor('industry', {
    header: 'industry',
    size: 150,
    cell: (prop) => <span class={'block p-4'}>{prop.getValue()}</span>,
    filterFn: 'arrIncludesSome'
  }),
  columnHelper.accessor('location', {
    header: 'location',
    size: 150,
    cell: (prop) => (
      <span class={'block p-4'}>
        {locationShortenFormat(
          prop.row.original.country,
          prop.row.original.state,
          prop.row.original.city,
          true
        )}
      </span>
    )
  }),
  columnHelper.accessor('country', {
    header: 'country',
    filterFn: 'arrIncludesSome'
  }),
  columnHelper.accessor('state', {
    header: 'state',
    filterFn: 'arrIncludesSome'
  }),
  columnHelper.accessor('city', {
    header: 'city',
    filterFn: 'arrIncludesSome'
  }),
  columnHelper.accessor('riskScore', {
    id: 'riskScore',
    header: 'risk score',
    enableSorting: true
  }),
  columnHelper.accessor('roleExp', {
    id: 'roleExp',
    header: () => <ColumnSort title={'Total Role Exp'} />,
    size: 135,
    cell: (prop) => (
      <span class={'block p-4'}>
        {prop.getValue() !== null ? convertMonthsToYearsMonths(prop.getValue()) : 'N\\A'}
      </span>
    ),
    enableSorting: true
  }),
  columnHelper.accessor('careerExp', {
    id: 'careerExp',
    size: 110,
    header: () => <ColumnSort title={'Career Exp'} />,
    cell: (prop) => (
      <span class={'block p-4'}>
        {prop.getValue() !== null ? convertMonthsToYearsMonths(prop.getValue()) : 'N\\A'}
      </span>
    ),
    enableSorting: true
  }),
  columnHelper.display({
    id: 'view',
    size: 50,
    cell: (prop) => <BaseButton onClick={() => viewCandidate(prop.row)} text={'View'}></BaseButton>
  }),
  columnHelper.display({
    id: 'shortlist',
    size: 60,
    header: () => (
      <BaseButton
        class={'ml-[-15px]  text-xxs leading-[10px]'}
        bg-color={'green'}
        height={'h-6'}
        text="Export"
        onClick={() => {
          const data = table.value.getRowModel().flatRows.map((row) => {
            return {
              Name: row.original.name,
              Position: row.original.position,
              Employer: row.original.employer,
              Industry: row.original.industry,
              Location: row.original.location,
              'Total Role Exp':
                row.original.roleExp !== null
                  ? convertMonthsToYearsMonths(row.original.roleExp)
                  : 'N\\A',
              'Career Exp':
                row.original.careerExp !== null
                  ? convertMonthsToYearsMonths(row.original.careerExp)
                  : 'N\\A',
              'Linkedin Profile URL': `https://www.linkedin.com/in/${row.original.candidateProfileId}`
            }
          })

          const csvConfig = mkConfig({
            useKeysAsHeaders: true,
            filename: `lookalikes_search_results_${props.archetypeData.name}_${new Date().toLocaleDateString()}`
          })
          const csv = generateCsv(csvConfig)(data)
          download(csvConfig)(csv)
        }}
      >
        <FileDownloadIcon class={'ml-2 w-3'} />
      </BaseButton>
    ),
    cell: (prop) => (
      <CandidateShortlistButton
        class={'!mr-0'}
        search-id={store.lookalikeCurrentHistorySearchId}
        archetype-li-profile-id={prop.row.original.archetypeProfileId}
        candidate-li-profile-id={prop.row.original.candidateProfileId}
        is-shortlisted={prop.row.original.isShortlisted}
        job-title={props.searchedJobTitle}
        onShortlistSucceed={() => emit('shortlistSucceed')}
        onShortlistFailed={(errorMsg) => emit('shortlistFailed', errorMsg)}
      />
    )
  })
]
const tableConfig = ref({
  defaultColumn: {
    enableSorting: false
  },
  get data() {
    return props.candidateData
  },

  initialState: {
    sorting: [
      {
        id: 'rank',
        desc: false // sort by name in descending order by default
      }
    ],
    columnVisibility: {
      riskScore: false, //hide the id column by default
      country: false,
      state: false,
      city: false
    },
    pagination: {
      pageIndex: 0, //custom initial page index
      pageSize: 100 //custom default page size
    }
  },
  columns: columns,
  getCoreRowModel: getCoreRowModel(),
  getFilteredRowModel: getFilteredRowModel(), //client-side filtering
  getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
  getSortedRowModel: getSortedRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  autoResetPageIndex: false
})

const table = ref(useVueTable(tableConfig.value))

const riskScoreColumn = computed(() => table.value.getColumn('riskScore'))

const sortByRiskScore = (event) => {
  if (riskScoreColumn.value.getSortIndex() === 0) {
    table.value.resetSorting()
  } else {
    riskScoreLoading.value = true

    setTimeout(() => {
      riskScoreColumn.value.toggleSorting(true)
      riskScoreLoading.value = false
    }, 2000)
  }
}

const viewCandidate = (data) => {
  setRecentViewed(data.original.candidateProfileId)
  // force update table to display recent view icon
  tableKey.value += 1

  window.open(
    router.resolve({
      name: 'candidate',
      params: {
        searchId: store.lookalikeCurrentHistorySearchId,
        candidateId: data.original.candidateId
      }
    }).href,
    '_blank'
  )
}

const scrollToTop = () => {
  tableContainer.value.scrollTop = 0
}

watch(
  () => store.lookalikeCurrentHistorySearchId,
  () => {
    table.value.resetColumnFilters()
  }
)
</script>
