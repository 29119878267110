<template>
  <div class="relative">
    <div
      v-if="loading"
      class="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center z-10"
    >
      <p class="mb-4 font-bold text-2xl">Searching...</p>
      <CoolLoader />
    </div>
    <form
      @submit.prevent="handleSubmit"
      :class="{ 'opacity-30': loading }"
      class="max-w-5xl m-auto mt-4"
    >
      <BaseCardWithHeader class="mb-4" title="Selected Location" custom-css-for-header="!p-2">
        <template #main>
          <div class="p-4 w-full">
            <SearchLocationFilter class="w-full" />
          </div>
        </template>
      </BaseCardWithHeader>
      <BaseCardWithHeader title="Build Your Own Archetype">
        <template #main>
          <div class="flex flex-col p-4">
            <p class="text-red-500 text-lg" v-if="noResults">No results found</p>
            <p>
              Create an ideal personalized Archetype for maximum search flexibility. Select current
              role and experience, industries or even specific companies. Add past experience roles
              for laser-focused results.
            </p>
            <div class="flex gap-4 justify-around p-4">
              <BaseCardWithHeader title="Current Experience" custom-css-for-header="!p-2">
                <template #main>
                  <div class="flex gap-4 flex-col p-2">
                    <SuggestedTitlesAutoComplete
                      class="mt-4 w-96"
                      title="* current role title"
                      label-size="small"
                      full-width
                      placeholder-text="Start typing a position title..."
                      :auto-complete-value="currentTitle"
                      :api-url="titleAutoCompleteApiUrl"
                      :query-params="locationQueryParams"
                      :focus-out-clear="true"
                      required
                      @option-selected="
                        (title) => {
                          currentTitle = title.title
                          currentCanonicalTitle = title.canonical_title
                        }
                      "
                    />

                    <Input
                      title="* MIN YEARS OF TOTAL ROLE EXPERIENCE"
                      label-size="small"
                      type="number"
                      label="small"
                      :min="0"
                      :max="100"
                      :value="totalRoleExp"
                      required
                      @input-change="(val) => (totalRoleExp = val)"
                    />
                    <Input
                      title="* MIN YEARS OF CAREER EXPERIENCE"
                      type="number"
                      label-size="small"
                      :min="0"
                      :max="100"
                      required
                      :value="careerExp"
                      @input-change="(val) => (careerExp = val)"
                    />
                    <AutoCompleteInput
                      title="Industries"
                      placeholder-text="Start typing an industry..."
                      :multi-select="true"
                      :multi-selected-values="convertArrToObj(industries)"
                      :loading="loadingIndustries"
                      :static-options="industriesOptions"
                      :load-static-data-on-focus="true"
                      :show-static-options-on-focus="true"
                      @focus="getIndustries"
                      @option-selected="(val) => (industries = val)"
                    />
                    <AutoCompleteInput
                      title="companies"
                      placeholder-text="Start typing a company name..."
                      :multi-select="true"
                      :multi-selected-values="convertArrToObj(companies)"
                      :api-url="companyAutoCompleteApiUrl"
                      max-list-height-class="max-h-[125px]"
                      :show-static-options-on-focus="true"
                      @option-selected="(val) => (companies = val)"
                    />
                    <BaseButton
                      class="mt-6"
                      type="submit"
                      text="Let`s Go"
                      text-align-center
                      :disabled="loading"
                    />
                  </div>
                </template>
              </BaseCardWithHeader>
              <BaseCardWithHeader title="Past Experience" custom-css-for-header="!p-2">
                <template #main>
                  <div class="p-2 max-h-[355px] overflow-y-auto">
                    <div v-for="(pastRole, idx) in pastRoles" :key="idx" class="flex gap-4 mt-4">
                      <SuggestedTitlesAutoComplete
                        class="w-96 self-end"
                        title="role title"
                        label-size="small"
                        placeholder-text="Start typing a position title..."
                        :api-url="titleAutoCompleteApiUrl"
                        :auto-complete-value="pastRole.title"
                        :focus-out-clear="true"
                        @option-selected="(val) => addPastRoleTitle(val, idx)"
                      />

                      <!-- <Input
                    class="self-end"
                    title="YEARS OF ROLE EXPERIENCE"
                    label-size="small"
                    type="number"
                    label="small"
                    :min="0"
                    :max="100"
                    @input-change="(val) => addPastRoleExp(val, idx)"
                  /> -->
                      <MinusIcon
                        class="ml self-center cursor-pointer mt-[14px]"
                        width="24px"
                        height="24px"
                        @click="removeRole(idx)"
                      />
                    </div>
                  </div>
                </template>
                <template #footer>
                  <div class="p-2">
                    <BaseButton
                      class="mt-6 w-full"
                      text-align-center
                      bg-color="green"
                      text="Add Role"
                      :disabled="pastRoles.length === 15"
                      type="button"
                      @click="addRole"
                    >
                      <PlusIcon class="ml-2" fill="white" />
                    </BaseButton>
                  </div>
                </template>
              </BaseCardWithHeader>
            </div>
          </div>
        </template>
      </BaseCardWithHeader>
    </form>
  </div>

  <Toaster
    v-if="error"
    message="Something went wrong. Please try again or contact support."
    color="red"
    :time="5000"
    @hide-toaster="error = false"
  />
</template>

<script setup lang="ts">
// Vue and related imports
import { computed, ref, onBeforeMount } from 'vue'
import type { Ref } from 'vue'
import { useRouter } from 'vue-router'

import axios from 'axios'
import { convertArrToObj } from '@/utils/type-format-helper'

// Component imports
import AutoCompleteInput from '@/components/inputs/AutoCompleteInput.vue'
import SuggestedTitlesAutoComplete from '@/views/Home/Tabs/LookalikeSearch/components/SuggestedTitlesAutoComplete.vue'
import SearchLocationFilter from '@/components/SearchLocationFilter.vue'
import Input from '@/components/inputs/Input.vue'
import BaseButton from '@/components/BaseButton.vue'
import PlusIcon from '@/components/icons/PlusIcon.vue'
import MinusIcon from '@/components/icons/MinusIcon.vue'
import CoolLoader from '@/components/CoolLoader.vue'
import BaseCardWithHeader from '@/components/Cards/BaseCards/BaseCardWithHeader.vue'
// Store imports
import { useLookalikesStore } from '@/stores/lookalikes'

import type { BuildArchetypeSearch, PastRole } from '@/components/BuildArchetypeSearch/interfaces'
import Toaster from '@/components/Toaster.vue'

interface IProps {
  withData?: boolean
}
const emit = defineEmits(['close', 'search-in-progress', 'search-finished', 'error'])

const props = defineProps<IProps>()

// Computed properties and refs
const lookalikesStore = useLookalikesStore()
const router = useRouter()

const MONTHS_PER_YEAR = 12 // 1 year = 12 months
const titleAutoCompleteApiUrl = `${import.meta.env.VITE_LOOKALIKES_API}/api/build-archetype/position-title-auto-complete`
const companyAutoCompleteApiUrl = `${import.meta.env.VITE_LOOKALIKES_API}/api/company-auto-complete`

const locationQueryParams = computed(
  () =>
    `countries=${JSON.stringify(lookalikesStore.countries)}&states=${JSON.stringify(lookalikesStore.states)}&cities=${JSON.stringify(lookalikesStore.cities)}`
)
const currentTitle: Ref<string> = ref('')
const currentCanonicalTitle: Ref<string> = ref('')
const totalRoleExp: Ref<number | null> = ref(null)
const careerExp: Ref<number | null> = ref(null)
const industries: Ref<string[]> = ref([])
const companies: Ref<string[]> = ref([])
const pastRoles: Ref<PastRole[]> = ref([{ title: '', canonical_title: '', roleExp: null }])
const loading: Ref<boolean> = ref(false)
const industriesOptions: Ref<string[]> = ref([])
const loadingIndustries = ref(false)
const error = ref(false)
const noResults = ref(false)

onBeforeMount(() => {
  if (props.withData) {
    currentTitle.value = lookalikesStore.buildArchetype.title
    currentCanonicalTitle.value = lookalikesStore.buildArchetype.canonical_title
    totalRoleExp.value = lookalikesStore.buildArchetype.total_role_exp / MONTHS_PER_YEAR
    careerExp.value = lookalikesStore.buildArchetype.career_exp / MONTHS_PER_YEAR
    industries.value = lookalikesStore.buildArchetype.industries
    companies.value = lookalikesStore.buildArchetype.companies
    pastRoles.value =
      lookalikesStore.buildArchetype.past_roles.length > 0
        ? lookalikesStore.buildArchetype.past_roles
        : [{ title: '', canonical_title: '', roleExp: null }]
  }
})

// Methods
const addRole = () => {
  if (pastRoles.value.length < 15) {
    pastRoles.value.push({ title: '', roleExp: null })
  }
}

const removeRole = (idx: number) => {
  if (pastRoles.value.length > 1) {
    pastRoles.value.splice(idx, 1)
  } else {
    pastRoles.value[0].title = ''
    pastRoles.value[0].roleExp = null
  }
}

const addPastRoleTitle = (title: { title: string; canonical_title: string }, idx: number) => {
  pastRoles.value[idx].title = title.title
  pastRoles.value[idx].canonical_title = title.canonical_title
}

const addPastRoleExp = (exp: number, idx: number) => {
  pastRoles.value[idx].roleExp = exp * MONTHS_PER_YEAR
}

const handleSubmit = async () => {
  loading.value = true
  noResults.value = false
  emit('search-in-progress')

  const payload: BuildArchetypeSearch = {
    countries: lookalikesStore.countries,
    states: lookalikesStore.states,
    cities: lookalikesStore.cities,
    title: currentTitle.value,
    canonical_title: currentCanonicalTitle.value,
    total_role_exp: totalRoleExp.value * MONTHS_PER_YEAR,
    career_exp: careerExp.value * MONTHS_PER_YEAR,
    industries: industries.value,
    companies: companies.value,
    past_roles: pastRoles.value
  }

  try {
    const res = await axios.post(
      `${import.meta.env.VITE_LOOKALIKES_API}/api/lookalikes/build-archetype-search`,
      payload
    )

    if (res.data.candidateData.length > 0) {
      lookalikesStore.setSearchResults(res.data)
      lookalikesStore.setBuildArchetype(payload)
      lookalikesStore.setLookalikeCurrentHistorySearchId(Number(res.data.searchId))

      router.push({ name: 'searchResults', params: { searchId: res.data.searchId } })
      emit('close')
    } else {
      noResults.value = true
    }
  } catch (err) {
    error.value = true
    emit('close')
  } finally {
    loading.value = false
    emit('search-finished')
  }
}

const getIndustries = async () => {
  if (industriesOptions.value.length > 0) {
    return
  }

  try {
    loadingIndustries.value = true
    const res = await axios.get(`${import.meta.env.VITE_LOOKALIKES_API}/api/industries`)
    industriesOptions.value = res.data.flat()
  } catch (error) {
    console.error(error)
  } finally {
    loadingIndustries.value = false
  }
}
</script>
